import React from 'react'
import Helmet from 'react-helmet'

import Layout from '../components/layout'

const RsvpSuccessNotAttending = () => {
    return (
        <Layout>
            <Helmet>
                <title>Thanks for responding!</title>
            </Helmet>
            <div id="main">
                <h1>Thanks for your response!</h1>
                <p>We're so sorry that you can't make it! If you change your mind, please do let us know.</p>
                <p><a href={"/"}>Home</a></p>
            </div>
        </Layout>
    )
}

export default RsvpSuccessNotAttending;